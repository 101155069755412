import { Button, Stack, Typography } from "@mui/material";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";

interface Props {
  /** Show the credit balance */
  creditBalance: string;
}

/**
 * Displays the user's credit balance along with an action button to view transactions.
 *
 * This component shows the current credit balance and provides a button to navigate
 * to the credit transactions page. When the button is clicked, an event is tracked
 * for analytics purposes.
 */
export function CreditsBalanceItem({ creditBalance }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  function handleViewTransactions(): void {
    trackEvent({
      name: WorkspaceEvents.viewCreditsTransactions,
      props: {
        origin: "credits",
      },
    });
  }

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography data-testid="credits-balance-item-balance">
        {creditBalance} credits
      </Typography>
      <Button
        variant="text"
        sx={{
          textTransform: "none",
          fontSize: "0.75rem",
        }}
        onClick={handleViewTransactions}
      >
        View transactions
      </Button>
    </Stack>
  );
}
