import { FaroButton } from "@components/common/faro-button";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Component to show the button add credits */
export function AddCredits(): JSX.Element {
  const { trackEvent } = useTrackEvent();

  function handleAddCredits(): void {
    trackEvent({
      name: WorkspaceEvents.addCredits,
      props: {
        origin: "add credits",
      },
    });
  }

  return (
    <FaroButton onClick={handleAddCredits} sx={{ textTransform: "none" }}>
      Add credits
    </FaroButton>
  );
}
